import React from 'react'
import "../styles/about.css"
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'

function About (props) {
const {image, body, title} = props

return (

  <div id="about-outer">
      <div className="wrapper">
        <div id="about-inner">

          <h2>{title}</h2>
          <div id="about-holder" class="grid about">
            <div className="width-3-12-m width-4-12">

              {props.image && image.asset && (
                <div className="image-holder">

                        <img
                          src={imageUrlFor(buildImageObj(props.image))
                            .width(1200)
                            .auto('format')
                            .url()}
                          alt={props.image.alt}
                          title={props.image.alt}
                        />
                  {image.caption && (
                    <span className="caption">
                      {image.caption}
                    </span>
                  )}

                </div>
              )}

            </div>
            <div className="width-9-12-m width-8-12 page">   

              {body && (
                  <div className="body-holder">
                      {body && <PortableText blocks={body} />}
                    </div>
                  )}
            </div>
          </div>
        </div>
      </div>
  </div>

)}

export default About